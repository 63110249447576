<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <h2>Olá {{ user.nome }}</h2>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="4"
            offset-md="4"
          >
            <v-btn
              color="primary"
              class="mr-4"
              to="/promotor/revendedores"
              block
            >
              REVENDEDORES
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="4"
          >
            <v-btn
              color="primary"
              class="mr-4"
              to="/promotor/dashboard"
              outlined
              block
            >
              MINHA DASHBOARD
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-list
      subheader
    >
      <v-subheader
        class="text-body-1"
      >
        <strong>DADOS DO REVENDEDOR</strong>
      </v-subheader>

      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Nome</v-list-item-title>
              <v-list-item-subtitle>{{ revendedor.cliente.nome }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>CPF</v-list-item-title>
              <v-list-item-subtitle>{{ revendedor.cliente.cpf }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Data de Nascimento</v-list-item-title>
              <v-list-item-subtitle>{{ revendedor.cliente.dataNascimento | date }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Telefone (SMS)</v-list-item-title>
              <v-list-item-subtitle>{{ revendedor.cliente.contatoSMS }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>E-mail</v-list-item-title>
              <v-list-item-subtitle>{{ revendedor.cliente.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Tipo do Cliente</v-list-item-title>
              <v-list-item-subtitle>{{ revendedor.cliente.tipo }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Status do Cliente</v-list-item-title>
              <v-list-item-subtitle>{{ revendedor.statusCliente }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Status em Analise</v-list-item-title>
              <v-list-item-subtitle>{{ revendedor.requestStatusTexto || 'Sem Analise' }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>

        <v-col
          v-show="revendedor.agendamento"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Data do Agendamento</v-list-item-title>
              <v-list-item-subtitle>{{ revendedor.agendamento }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
        >
          <v-alert
            v-if="revendedor.requestStatusId === statusEnum.None"
            border="left"
            colored-border
            elevation="1"
            type="warning"
          >
            <v-row align="center">
              <v-col class="grow">
                É necessário criar a analise de crédito para continuar.
              </v-col>
              <v-col class="shrink">
                <v-btn
                  color="warning"
                  block
                  outlined
                  @click.stop="openDialog"
                >
                  <v-icon left>
                    mdi-file
                  </v-icon>
                  CRIAR ANALISE
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>

          <v-alert
            v-if="revendedor.requestStatusId === statusEnum.EmAnalise"
            border="left"
            colored-border
            elevation="1"
            type="error"
          >
            <v-row align="center">
              <v-col class="grow">
                A analise de crédito ainda não foi realizada. Atualize o cadastro sempre que achar necessário.
              </v-col>
              <v-col class="shrink">
                <v-btn
                  color="error"
                  block
                  outlined
                  @click.stop="loadData"
                >
                  <v-icon left>
                    mdi-refresh
                  </v-icon>
                  ATUALIZAR
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>

          <v-alert
            v-if="revendedor.requestStatusId === statusEnum.Aprovado && revendedor.statusCliente === 'ANALISE'"
            border="left"
            colored-border
            elevation="1"
            type="success"
          >
            <v-row align="center">
              <v-col class="grow">
                Parabéns, a analise de crédito foi aprovada. Clique aqui para finalizar o cadastro.
              </v-col>
              <v-col class="shrink">
                <v-btn
                  color="success"
                  block
                  outlined
                  @click.stop="openConsignadoDialog"
                >
                  <v-icon left>
                    mdi-check-circle
                  </v-icon>
                  FINALIZAR
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>

          <v-alert
            v-if="revendedor.requestStatusId === statusEnum.Recusado && revendedor.statusCliente === 'ANALISE'"
            border="left"
            colored-border
            elevation="1"
            type="warning"
          >
            <v-row align="center">
              <v-col class="grow">
                A analise de crédito foi recusada. Clique aqui para finalizar o cadastro.
              </v-col>
              <v-col class="shrink">
                <v-btn
                  color="warning"
                  block
                  outlined
                  @click.stop="addProfile('catalogo')"
                >
                  <v-icon left>
                    mdi-check-circle
                  </v-icon>
                  FINALIZAR
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>

          <v-alert
            v-if="revendedor.requestStatusId === statusEnum.Ilegivel"
            border="left"
            colored-border
            elevation="1"
            type="warning"
          >
            <v-row align="center">
              <v-col class="grow">
                Não foi possivel realizar a analise de crédito. Clique aqui para refazer a analise.
              </v-col>
              <v-col class="shrink">
                <v-btn
                  color="warning"
                  block
                  outlined
                  @click.stop="openDialog"
                >
                  <v-icon left>
                    mdi-check-circle
                  </v-icon>
                  REENVIAR
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>

          <v-alert
            v-if="revendedor.requestStatusId === statusEnum.Aprovado && revendedor.statusCliente === 'ATIVO'"
            border="left"
            colored-border
            elevation="1"
            type="success"
          >
            <v-row align="center">
              <v-col class="grow">
                Parabéns, o revendedor está ativo. Processo finalizado!
              </v-col>
              <v-col class="shrink">
                <v-btn
                  color="success"
                  block
                  outlined
                  @click.stop="sendWhatsapp"
                >
                  <v-icon left>
                    mdi-whatsapp
                  </v-icon>
                  ENVIAR WHATSAPP
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>

          <v-alert
            v-if="revendedor.requestStatusId === statusEnum.Recusado && revendedor.statusCliente === 'ATIVO'"
            border="left"
            colored-border
            elevation="1"
            type="success"
          >
            <v-row align="center">
              <v-col class="grow">
                Parabéns, o revendedor está ativo. Processo finalizado!
              </v-col>
              <v-col class="shrink">
                <v-btn
                  color="success"
                  block
                  outlined
                  @click.stop="sendWhatsapp"
                >
                  <v-icon left>
                    mdi-whatsapp
                  </v-icon>
                  ENVIAR WHATSAPP
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
    </v-list>

    <v-dialog
      v-model="showDialog"
      max-width="800"
      persistent
    >
      <v-container
        style="background-color:white;"
      >
        <v-row>
          <v-col
            cols="12"
          >
            <documentos
              ref="refDocumentos"
              :files="files"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-btn
              color="primary"
              block
              :disabled="!files.allFilesFilled"
              @click.stop="submitFiles"
            >
              CADASTRAR
            </v-btn>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-btn
              color="error"
              block
              outlined
              @click.stop="closeDialog"
            >
              CANCELAR
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>

    <rotina-consignado
      ref="rotinaConsignadoRef"
      :model="model"
      @submit="submit"
    />
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { StatusEnum } from '../../solicitacoes-credito/status.enum'

  import Documentos from '../components/Documentos'
  import RotinaConsignado from '../components/RotinaConsignado'

  export default {
    components: {
      Documentos,
      RotinaConsignado,
    },

    props: {
      id: {
        type: String,
        default: () => { return '0' },
      },
    },

    data: () => ({
      statusEnum: StatusEnum,
      showDialog: false,
      revendedor: {
        cliente: {
          id: null,
          codigoStyllus: null,
          nome: '',
          cpf: '',
          dataNascimento: '',
          contatoSMS: '',
          email: '',
          tipo: '',
        },
        requestStatusId: null,
        requestStatusTexto: '',
        statusCliente: '',
        centroDistribuicao: {},
      },
      items: [],
      files: {
        selfieComDocumento: null,
        selfie: null,
        documento: null,
        comprovanteResidencia: null,
        allFilesFilled: false,
      },
      model: {
        clienteId: null,
        centroDistribuicaoId: null,
        profile: '',
      },
    }),

    computed: {
      ...mapGetters(['user']),
    },

    mounted () {
      this.loadData()
    },

    methods: {
      loadData () {
        this.$http.get(`/promotor/revendedor/${this.id}`)
          .then(({ data }) => {
            this._id = data.id

            this.revendedor.requestStatusId = data.requestStatusId
            this.revendedor.requestStatusTexto = data.requestStatusTexto
            this.revendedor.statusCliente = String(data.statusCliente).toUpperCase()

            this.model.clienteId = data.cliente.id

            this.revendedor.cliente.id = data.cliente.id
            this.revendedor.cliente.codigoStyllus = data.cliente.codigoStyllus
            this.revendedor.cliente.nome = data.cliente.nome
            this.revendedor.cliente.cpf = data.cliente.cpf
            this.revendedor.cliente.dataNascimento = data.cliente.dataNascimento
            this.revendedor.cliente.contatoSMS = data.cliente.contatoSMS
            this.revendedor.cliente.email = data.cliente.email
            this.revendedor.cliente.tipo = String(data.cliente.tipo).toUpperCase()
            this.revendedor.centroDistribuicao = data.centroDistribuicao
          })
      },

      addProfile (profile) {
        this.model.profile = profile

        this.$http.put(`/promotor/revendedor/${this._id}`, this.model)
          .then(_ => this.$toast.success('Processo finalizado com sucesso'))
          .then(this.loadData)
      },

      openDialog () {
        this.showDialog = true
      },

      closeDialog () {
        this.showDialog = false
      },

      submitFiles () {
        const formData = new FormData()

        formData.append('ClienteJson', JSON.stringify(this.revendedor.cliente))

        formData.append('SelfieWithDocument', this.files.selfieComDocumento)
        formData.append('Selfie', this.files.selfie)
        formData.append('DocumentFront', this.files.documentoFrente)
        formData.append('ComprovanteResidencia', this.files.comprovanteResidencia)

        if (this.files.documentoVerso) {
          formData.append('DocumentBack', this.files.documentoVerso)
        }

        this.$http.post('promotor/revendedor/CreditRequest', formData)
          .then(_ => this.$toast.success('Analise de crédito solicitada com sucesso'))
          .then(this.closeDialog)
          .then(this.loadData)
      },

      openConsignadoDialog () {
        this.$refs.rotinaConsignadoRef.openDialog()
      },

      submit () {
        this.$refs.rotinaConsignadoRef.closeDialog()

        this.$http.put(`/promotor/revendedor/${this._id}`, this.model)
          .then(_ => this.$toast.success('Processo finalizado com sucesso'))
          .then(this.loadData)
      },

      sendWhatsapp () {
        const promotor = this.user.nome
        const cliente = this.revendedor.cliente
        var mensagem = ''
        var destino = '5521975311655'
        if (cliente.tipo === 'CONSIGNADA') {
          mensagem =
            `*Promotor:* ${promotor}%0a
            *Revendedor:* ${cliente.nome}%0a
            *ID:* ${cliente.codigoStyllus}%0a
            *CPF:* ${cliente.cpf}%0a
            *Email:* ${cliente.email}%0a
            *Celular:* ${cliente.contatoSMS}%0a
            *Nome do Escritório:* ${this.revendedor.centroDistribuicao.nome}%0a
            *Data:* [00/00/0000] %0a
            *Hora:* [00:00] %0a
            `
        } else {
          destino = '55' + cliente.contatoSMS.toString().replace(/\D/g, '')
          mensagem = `Olá, ${cliente.nome}.%0a*Parabéns*, você já pode começar a revender! *Acesse seu escritório virtual* https://styllus.online/ev. %0aA sua senha já foi enviada por sms.`
        }
        const link = `https://api.WhatsApp.com/send?phone=${destino}&text=${mensagem}`
        window.open(link, '_blank')
      },
    },
  }
</script>
