<template>
  <v-card
    outlined
  >
    <v-card-title
      class="mb-4"
    >
      Documentos para analise de crédito
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
        >
          <p
            class="text-body-2"
            :class="{ 'success--text': selfieComDocumento != null }"
          >
            Precisamos que você nos envie uma foto atualizada segurando seu RG ou CNH.
          </p>
          <v-file-input
            id="selfieComDocumento"
            v-model="selfieComDocumento"
            label="TIRE UMA SELFIE SEGURANDO SEU DOCUMENTO"
            accept="application/pdf, image/*"
            dense
            outlined
            @change="file => onchange('selfieComDocumento', file)"
          />
        </v-col>
        <v-col
          cols="12"
        >
          <p
            class="text-body-2"
            :class="{ 'success--text': selfie != null }"
          >
            Precisamos que você nos envie uma foto atualizada.
          </p>
          <v-file-input
            id="selfie"
            v-model="selfie"
            label="TIRE UMA SELFIE ONDE APENAS VOCÊ APAREÇA"
            accept="application/pdf, image/*"
            dense
            outlined
            @change="file => onchange('selfie', file)"
          />
        </v-col>
        <v-col
          cols="12"
        >
          <p
            class="text-body-2"
            :class="{ 'success--text': documentoFrente != null }"
          >
            Precisamos que você nos envie uma foto atualizada do seu RG e CPF ou CNH.
          </p>
          <v-file-input
            id="documentoFrente"
            v-model="documentoFrente"
            label="TIRE UMA FOTO DE UM DOCUMENTO DE IDENTIFICAÇÃO ABERTO, ONDE TODAS AS INFORMAÇÕES ESTEJAM LEGÍVEIS"
            accept="application/pdf, image/*"
            dense
            outlined
            @change="file => onchange('documentoFrente', file)"
          />
        </v-col>
        <v-col
          cols="12"
        >
          <p
            class="text-body-2"
            :class="{ 'success--text': documentoVerso != null }"
          >
            Precisamos que você nos envie uma foto atualizada do verso seu RG e CPF ou CNH. (Opcional)
          </p>
          <v-file-input
            id="documentoVerso"
            v-model="documentoVerso"
            label="TIRE UMA FOTO DO VERSO DE UM DOCUMENTO DE IDENTIFICAÇÃO, ONDE TODAS AS INFORMAÇÕES ESTEJAM LEGÍVEIS"
            accept="application/pdf, image/*"
            dense
            outlined
            @change="file => onchange('documentoVerso', file)"
          />
        </v-col>
        <v-col
          cols="12"
        >
          <p
            class="text-body-2"
            :class="{ 'success--text': comprovanteResidencia != null }"
          >
            Precisamos que você nos envie uma foto ou arquivo de um comprovante de residência atualizado.
          </p>
          <v-file-input
            id="comprovanteResidencia"
            v-model="comprovanteResidencia"
            label="ENVIE UM COMPROVANTE DE RESIDÊNCIA OU TIRE UMA FOTO LEGÍVEL"
            accept="application/pdf, image/*"
            dense
            outlined
            @change="file => onchange('comprovanteResidencia', file)"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    props: {
      files: {
        type: Object,
        default: () => ({}),
      },
    },

    data: () => ({
      selfieComDocumento: null,
      selfie: null,
      documentoFrente: null,
      documentoVerso: null,
      comprovanteResidencia: null,
    }),

    computed: {
      allFilesFilled () {
        return [
          this.files.selfieComDocumento,
          this.files.selfie,
          this.files.documentoFrente,
          this.files.comprovanteResidencia,
        ].every(file => { return file != null })
      },
    },

    watch: {
      allFilesFilled (value) {
        this.files.allFilesFilled = value
      },
    },

    methods: {
      onchange (key, file) {
        if (!file) {
          this.files[key] = null
        } else {
          this.compress(file)
            .then(compressFile => {
              this.files[key] = compressFile
            })
        }
      },

      compress (file) {
        return new Promise((resolve, reject) => {
          const fileName = file.name
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = event => {
            const img = new Image()
            img.src = event.target.result
            img.onload = () => {
              const width = Math.min(640, img.width)
              const scaleFactor = width / img.width
              const height = scaleFactor * img.height
              const elem = document.createElement('canvas')
              elem.width = width
              elem.height = height
              const ctx = elem.getContext('2d')
              ctx.drawImage(img, 0, 0, width, height)
              ctx.canvas.toBlob(blob => {
                const file = new File([blob], fileName, {
                  type: 'image/jpeg',
                  lastModified: Date.now(),
                })
                resolve(file)
              }, 'image/jpeg', 1)
            }
            reader.onerror = reject
          }
        })
      },
    },
  }
</script>
