<template>
  <v-dialog
    v-model="showDialog"
    max-width="540"
    persistent
  >
    <v-card>
      <v-card-title>
        <strong>Rotina Consignado</strong>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
          >
            <v-select
              v-model="stateId"
              label="Estados"
              :items="states"
              item-text="display"
              item-value="id"
              :disabled="states.length === 0"
              required
              dense
              outlined
            />
            <v-select
              v-model="cityId"
              label="Cidades"
              :items="cities"
              item-text="display"
              item-value="id"
              :disabled="cities.length === 0"
              required
              dense
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="centro in centros"
            :key="centro.centroDistribuicaoId"
            cols="12"
          >
            <v-card
              :key="centro.centroDistribuicaoId"
              :class="{
                'v-card--active primary--text': centroId === centro.centroDistribuicaoId
              }"
              :elevation="centroId === centro.centroDistribuicaoId ? 3 : 0"
              :outlined="centroId !== centro.centroDistribuicaoId"
              @click.stop="centroId = centro.centroDistribuicaoId"
            >
              <v-card-title>
                <v-icon
                  :class="{ 'primary--text': centroId === centro.centroDistribuicaoId }"
                  left
                >
                  mdi-radiobox{{
                    centroId === centro.centroDistribuicaoId ? '-marked' : '-blank'
                  }}
                </v-icon>
                {{ centro.nome }}
              </v-card-title>
              <v-card-text
                class="pa-2"
              >
                <v-col
                  v-show="centro.googleMaps"
                  cols="12"
                >
                  <iframe
                    width="100%"
                    :src="centro.googleMaps"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  />
                </v-col>
                <v-col
                  cols="12"
                >
                  <p>
                    <strong>Telefone:</strong>
                    {{ centro.contato }}
                  </p>
                  <p
                    class="mb-0"
                  >
                    {{ centro.logradouro }}, Nº: {{ centro.numero }} - {{ centro.complemento }}. {{ centro.pontoDeReferencia }}
                  </p>
                </v-col>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          :disabled="!centroId"
          @click.stop="submit"
        >
          FINALIZAR
        </v-btn>

        <v-btn
          color="error"
          outlined
          @click.stop="closeDialog"
        >
          CANCELAR
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      model: {
        type: Object,
        default: () => { return {} },
      },
    },

    data: () => ({
      rules: [v => !!v || 'Campo é obrigatório!'],
      valid: false,
      showDialog: false,
      states: [],
      cities: [],
      centros: [],
      stateId: 0,
      cityId: 0,
      centroId: 0,
      date: '',
      time: '',
      naoPossuiDataCadastro: false,
    }),

    watch: {
      stateId (id) {
        if (id) {
          this.$http
            .get(`endereco/cidade/centrodistribuicao?estadoId=${id}`)
            .then(({ data }) => {
              this.cities = data
            })
        }
      },

      cityId (id) {
        if (id) {
          this.$http
            .get(`centros-distribuicao/cidades/${id}`)
            .then(({ data }) => {
              this.centros = data
            })
        }
      },
    },

    mounted () {
      this.loadStates()
    },

    methods: {
      loadStates () {
        this.$http
          .get('endereco/estado/centrodistribuicao')
          .then(({ data }) => {
            this.states = data
          })
      },

      resetData () {
        this.stateId = 0
        this.cityId = 0
        this.centroId = 0
        this.date = ''
        this.time = ''
      },

      openDialog () {
        this.showDialog = true
      },

      closeDialog () {
        this.showDialog = false
        this.resetData()
      },

      submit () {
        this.model.centroDistribuicaoId = this.centroId
        this.model.profile = 'consignado'
        this.model.dataCadastro = null
        this.$emit('submit')
      },
    },
  }
</script>
